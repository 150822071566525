import React, { useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useInputState from '../../hooks/userInputState'
import { LOGIN } from '../../graphql/queries'
import { useApolloClient } from '@apollo/react-hooks'
import { createCookie } from '../../utils/helpers'
import {
  BASE_APP_URL,
  // API_URL_PCS,
  // API_URL,
  CAPTCHA_SITE_KEY,
  isDevelopmentUse,
} from '../../utils/globals'
// import axios from 'axios'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ResultPopup from '../shared-components/ResultPopup'
import ReCAPTCHA from 'react-google-recaptcha'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const LoginForm = ({ doLoading, doForgot }) => {
  const [emailValue, handleEmailValueChange] = useInputState('')
  const [passwordValue, handlePasswordValueChange] = useInputState('')
  const client = useApolloClient()
  const [error, setError] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const captchaRef = useRef(null)

  const handleAuthLogin = async (e) => {
    // const token = captchaRef.current.getValue()
    const token = true;

    e.preventDefault()
    if (!token) {
      setAlert(true)
      setAlertMessage('Harap isi captcha.')
    } else {
      try {
        doLoading(true)
        setError(false)
        const { loading, data } = await client.query({
          query: LOGIN,
          variables: {
            email: emailValue,
            password: passwordValue,
          },
        })
        if (!loading) {
          // const dataPost = {
          //   email: emailValue,
          //   password: passwordValue,
          // }
          // try {
          //    await axios.post(`${API_URL_PCS}/auth/login`, dataPost)
          //    const reqChat = await axios.get(`${API_URL}/global/chat/get`, {
          //     headers: {
          //       Authorization: `Bearer ${data.getLogin.oauth.access_token}`,
          //     },
          //   })

          //   data.getLogin.chat = reqChat.data*/
          //   doLoading(false)
          //   setError(false)
          // } catch (error) {
          //   doLoading(false)
          //   setError(false)
          // }
          console.log(data);
          createCookie('userData', JSON.stringify(data.getLogin), 0.0417)

          if (isDevelopmentUse) {
            window.alert(`Cookie has been created`)
          } else {
            window.location = BASE_APP_URL
          }
        }
      } catch (error) {
        const graphQLErrors = error.graphQLErrors

        console.error(error)

        if (
          graphQLErrors &&
          graphQLErrors[0] &&
          graphQLErrors[0].message === 'NO PLACEMENT'
        ) {
          setAlertMessage(
            'To be able to login. Please make sure you have been placed in a company registered with WLB'
          )
        } else {
          setAlertMessage('Email atau Kata Sandi Salah, Silahkan coba lagi.')
        }

        captchaRef.current.reset()
        doLoading(false)
        setAlert(true)
      }
    }
  }

  const handleForgot = () => {
    doForgot(true)
  }
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const [values, setValues] = useState({
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleAlert = () => {
    setAlert(false)
  }

  return (
    <div className="flex flex-col">
      <form name="loginForm" noValidate onSubmit={handleAuthLogin}>
        <TextField
          label="Email"
          autoFocus
          type="email"
          name="email"
          value={emailValue}
          onChange={handleEmailValueChange}
          variant="outlined"
          required
          fullWidth
          className="mb-4 w-full"
        />

        <TextField
          label="Kata Sandi"
          type={values.showPassword ? 'text' : 'password'}
          value={passwordValue}
          onChange={handlePasswordValueChange}
          className="mb-4 w-full"
          variant="outlined"
          required
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} ref={captchaRef} />
        <div className="flex flex- mt-4" style={{ justifyContent: 'flex-end' }}>
          <Typography
            className="mb-2 cursor-pointer font-medium"
            style={{
              fontSize: '14px',
              color: '#5CC4E9',
            }}
            onClick={() => handleForgot()}
            align="right"
          >
            Lupa Kata Sandi?
          </Typography>
        </div>
          <Typography
            className="mb-2 cursor-pointer font-medium"
            style={{
              fontSize: '14px',
              color: '#5CC4E9',
            }}
            align="left"
          >
            Dengan mengakses situs ini, Anda menyetujui <span style={{
              fontSize: '14px',
              color: '#0000ff',
            }}
            onClick={() => handleOpenModal()}
            >Kebijakan Privasi</span> kami.
          </Typography>
          <Dialog
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Kebijakan Privasi"}
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText id="alert-dialog-description">
                Let Google help apps determine location. This means sending anonymous
                location data to Google, even when no apps are running.
              </DialogContentText> */}
              <h3 className="mb-3">I. Ketentuan Umum</h3>
              <p className="pl-3 mb-3">
                  Kebijakan Privasi ini menjelaskan bagaimana Kami mengumpulkan, menggunakan, mengungkapkan, dan melindungi Data Pribadi Anda ketika Anda menggunakan layanan di dalam Platform SmartASN. Dengan menggunakan Platform SmartASN, Anda menyatakan bahwa setiap data dan informasi yang diberikan adalah benar dan sah serta memberikan persetujuan kepada Kami untuk memperoleh, mengumpulkan, menyimpan, mengelola, dan mempergunakan data dan informasi tersebut sesuai dengan Ketentuan dan Kebijakan Privasi ini.
              </p>

              <h3 className="mb-3">II. Definisi</h3>
              <ol type="a" className="pl-3 mb-3">
                  <li>
                      <strong>"Platform SmartASN"</strong> adalah satu platform yang menjadi tempat atau wadah kolaborasi ASN untuk memperoleh employee services (layanan kepegawaian) dan human capital practices (penerapan human capital) dalam ekosistem digital manajemen ASN baik melalui situs web maupun aplikasi mobile.
                  </li>
                  <li>
                      <strong>"Pengguna"</strong> adalah pegawai ASN yang menggunakan Platform SmartASN.
                  </li>
                  <li>
                      <strong>"Kami"</strong> adalah Instansi Pemerintah yang mengelola Platform SmartASN terdiri atas Kementerian Pendayagunaan Aparatur Negara (Kementerian PANRB), Badan Kepegawaian Negara (BKN), Lembaga Administrasi Negara (LAN), Kementerian Komunikasi dan Informatika (Kementerian Kominfo), Badan Siber dan Sandi Negara (BSSN), dan Badan Riset dan Inovasi Nasional (BRIN).
                  </li>
                  <li>
                      <strong>"Pihak Ketiga"</strong> adalah pihak yang bekerja sama dengan Kami dalam menyediakan Platform SmartASN.
                  </li>
                  <li>
                      <strong>"Data Pribadi"</strong> adalah data tentang orang perseorangan yang teridentifikasi atau dapat diidentifikasi secara tersendiri atau dikombinasi dengan informasi lainnya baik secara langsung maupun tidak langsung melalui sistem elektronik atau nonelektronik yang terdiri atas Data Pribadi yang bersifat spesifik dan Data Pribadi yang bersifat umum.
                  </li>
                  <li>
                      <strong>"Data Pribadi yang bersifat umum"</strong> adalah Data Pribadi yang meliputi (i) nama lengkap; (ii) jenis kelamin; (iii) kewarganegaraan; (iv) agama; (v) status perkawinan; dan/atau (vi) Data Pribadi yang dikombinasikan untuk mengidentifikasi seseorang.
                  </li>
                  <li>
                      <strong>"Data Pribadi yang bersifat spesifik"</strong> adalah Data Pribadi yang meliputi (i) data dan informasi kesehatan; (ii) data biometrik; (iii) data genetika; (iv) catatan kejahatan; (vi) data anak; (vii) data keuangan pribadi; dan/atau (iv) data lainnya sesuai dengan ketentuan peraturan perundang-undang.
                  </li>
                  <li>
                      <strong>"Pemrosesan" atau "Memproses"</strong> adalah kegiatan yang meliputi pemerolehan dan pengumpulan; pengolahan dan penganalisisan; penyimpanan; perbaikan dan pembaruan; penampilan, pengumuman, transfer, penyebarluasan, atau pengungkapan; dan/atau penghapusan atau pemusnahan.
                  </li>
              </ol>

              <h3 className="mb-3">III. Perolehan dan Pengumpulan Data Pribadi</h3>
              <ol type="a" className="pl-3 mb-3">
                  <li>
                      Kami memperoleh dan mengumpulkan Data Pribadi Anda melalui berbagai cara termasuk namun tidak terbatas:
                      <ol>
                          <li>
                              Pada saat Anda melakukan sign in ke dalam Platform SmartASN;
                          </li>
                          <li>
                              Pada saat Anda melakukan pemutakhiran (update) atas Data Pribadi Anda yang sebelumnya telah disimpan oleh Kami;
                          </li>
                          <li>
                              Pada saat Data Pribadi Anda Kami unggah ke dalam Platform SmartASN;
                          </li>
                          <li>
                              Pada saat Anda membuat perjanjian atau berpartisipasi dalam aktivitas apapun yang diselenggarakan melalui Platform SmartASN.
                          </li>
                      </ol>
                  </li>
                  <li>
                      Data Pribadi ini mungkin mencakup, namun tidak terbatas pada:
                      <ul>
                          <li>Data Identitas, termasuk nama lengkap, nama pengguna, serta termpat dan tanggal lahir;</li>
                          <li>Data Kontak, termasuk alamat email;</li>
                          <li>Data teknis termasuk data lokasi dan informasi perangkat.</li>
                      </ul>
                  </li>
              </ol>

              <h3 className="mb-3">IV. Tujuan Pemrosesan Data Pribadi</h3>
              <ol type="a" className="pl-3 mb-3">
                  <li>
                      Kami Memproses Data Pribadi Anda, baik Data Pribadi yang bersifat umum maupun Data Pribadi yang bersifat spesifik, untuk tujuan-tujuan berikut ini:
                      <ol>
                          <li>
                              Penyediaan fitur-fitur dan/atau layanan untuk Anda yang merupakan Pengguna Platform SmartASN;
                          </li>
                          <li>
                              Peningkatan layanan Pengguna untuk lebih efektif dalam memberikan dukungan teknis dan meningkatkan layanan;
                          </li>
                          <li>
                              Pengiriman informasi layanan terbaru, pembaruan keamanan, informasi produk atau layanan terkait, dan pembaruan status pada tampilan pemeliharaan atau ketersediaan layanan;
                          </li>
                          <li>
                              Tujuan administrasi internal, terutama untuk analisis data, pengembangan fitur, dan tujuan terkait kolaborasi;
                          </li>
                          <li>
                              Tujuan lainnya sepanjang tujuan tersebut tidak dilarang oleh peraturan perundang-undangan yang berlaku.
                          </li>
                      </ol>
                  </li>
              </ol>

              <h3 className="mb-3">V. Pengungkapan Data Pribadi</h3>
              <p className="pl-3 mb-3">
                  Kami menghargai kerahasiaan Data Pribadi Anda dan Kami berkomitmen untuk tidak menjual, menyewakan, menampilkan, atau menyebarkan Data Pribadi Anda tanpa persetujuan dari Anda. Kami hanya akan mengungkapkan Data Pribadi Anda kepada Pihak Ketiga atas persetujuan Anda untuk tujuan-tujuan berikut ini dan tujuan-tujuan lainnya yang diperbolehkan oleh peraturan perundang-undangan yang berlaku:
              </p>
              <ol type="a" className="pl-3 mb-3">
                  <li>
                      Penggunaan Data Pribadi yang dilakukan oleh Pihak Ketiga dalam rangka penyediaan, pengujian, penelitian, analisis, ataupun peningkatan kualitas fitur dan layanan Platform SmartASN;
                  </li>
                  <li>
                      Pelaksanaan kerja sama dengan Pihak Ketiga dengan maksud agar mereka dapat (i) membantu Kami dalam menyediakan fitur-fitur Kami; (ii) untuk tujuan audit internal; (iii) Memproses semua bentuk aktivitas Anda dalam Platform SmartASN; maupun (iv) tujuan lainnya sepanjang tidak dilarang oleh peraturan perundang-undangan yang berlaku. Seluruh Pihak Ketiga yang bekerja sama dengan Kami berkomitmen untuk Memproses Data Pribadi yang mereka terima dari Kami sesuai dengan peraturan perundang-undangan yang berlaku;
                  </li>
                  <li>
                      Pihak Ketiga sebagai auditor yang berwenang di mana Pihak Ketiga tersebut diharuskan untuk mematuhi persyaratan kerahasiaan agar tidak mengungkapkan, menjual, memperdagangkan, mendistribusikan, dan/atau menggunakan Data Pribadi Anda;
                  </li>
                  <li>
                      Sehubungan dengan pengungkapan Data Pribadi Anda kepada Pihak Ketiga, Kami akan mewajibkan Pihak Ketiga dan pihak lain tersebut untuk menjaga keamanan dan kerahasiaan Data Pribadi Anda sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.
                  </li>
              </ol>

              <h3 className="mb-3">VI. Penyimpanan dan Retensi</h3>
              <ol type="a" className="pl-3 mb-3">
                  <li>
                      Data Pribadi Anda akan disimpan sementara pada cookies/local storage perangkat Anda ketika Anda mengakses atau menggunakana Platform SmartASN;
                  </li>
                  <li>
                      Data Pribadi Anda akan disimpan dalam format elektronik pada lokasi penyimpanan yang sesuai dengan peraturan perundang-undangan;
                  </li>
                  <li>
                      Data Pribadi Anda akan disimpan dalam jangka waktu yang sesuai dengan peraturan perundang-undangan.
                  </li>
              </ol>

              <h3 className="mb-3">VII. Keamanan Data Pribadi</h3>
              <p className="pl-3 mb-3">
                  Kami akan melindungi dan mengamankan Data Pribadi Anda, baik Data Pribadi yang bersifat umum maupun Data Pribadi yang bersifat spesifik, yang tersimpan dalam sistem penyimpanan Kami (termasuk membatasi akses terhadap Data Pribadi Anda kepada pegawai Kami berdasarkan kebutuhan untuk mengetahui. Pemrosesan Data Pribadi Anda hanya akan dilakukan dengan tata cara yang diizinkan dan diharuskan untuk menjaga Data Pribadi Anda dengan kerahasiaan) dari tindakan Pemrosesan Data Pribadi oleh pihak-pihak yang tidak berwenang dan dari tindakan Pemrosesan Data Pribadi yang bertentangan dengan peraturan perundang-undangan yang berlaku.
              </p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        <div>
          {error && (
            <Typography style={{ color: 'red', marginBottom: '20px' }}>
              Email atau Kata Sandi Salah, Silahkan coba lagi
            </Typography>
          )}
        </div>
        <div className="flex flex-row">
          <Button
            variant="outlined"
            style={{
              textTransform: 'none',
              background: '#02798F',
              height: 48,
              outline: 'none',
            }}
            className="w-full mt-2 text-white transform-none text"
            type="submit"
          >
            Masuk
          </Button>
          {/* <Button
            variant="outlined"
            className="w-full mt-2 text-primary transform-none"
            style={{
              textTransform: 'none',
              border: '1px solid #024c62',
            }}
          >
            Register
          </Button> */}
        </div>
      </form>
      <ResultPopup
        open={alert}
        handleClose={handleAlert}
        feature={`Gagal Masuk`}
        message={alertMessage}
        type="Back"
        status="notNow"
      />
    </div>
  )
}

export default LoginForm
