export const isDevelopmentUse = /[&?]development=(true|on|1)\b/.test(
  window.location.search
)

const {
  REACT_APP_GRAPHQL_URL,
  // REACT_APP_GRAPHQL_URL_WS,
  REACT_APP_API_URL,
  REACT_APP_BASE_APP_URL,
  REACT_APP_AUTH_URL,
  REACT_APP_COOKIE,
  REACT_APP_API_URL_PCS,
  REACT_APP_SENTRY,
  REACT_APP_SENTRY_ENV,
  REACT_APP_CAPTCHA_KEY,
} = process.env
export const GRAPHQL_URL = REACT_APP_GRAPHQL_URL
// export const GRAPHQL_WS_URL = REACT_APP_GRAPHQL_URL_WS
export const API_URL = REACT_APP_API_URL
export const API_URL_PCS = REACT_APP_API_URL_PCS
export const BASE_APP_URL = REACT_APP_BASE_APP_URL
export const BASE_AUTH_URL = REACT_APP_AUTH_URL
export const APP_COOKIE = !isDevelopmentUse ? REACT_APP_COOKIE : 'localhost'
export const TOKEN =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFub255bW91cyIsInVzZXJuYW1lIjoiYW5vbnltb3VzIiwiZW1haWwiOiJhbm9ueW1vdXNAYW5vbnltb3VzIiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJhbm9ueW1vdXMiLCJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImFub255bW91cyJdLCJ4LWhhc3VyYS11c2VyLWVtYWlsIjoiYW5vbnltb3VzQGFub255bW91cyIsIngtaGFzdXJhLXVzZXItaWQiOiJhbm9ueW1vdXMiLCJ4LWhhc3VyYS1vcmctaWQiOiJhbm9ueW1vdXMifSwiaWF0IjoxNTY0MDQyMzEwfQ.5saGhuAi85VRDChrfyV3KkgOFVzY7OsK20fkrlXmq9LuKiM_eL_DeIe2y2FyjD_a-Gk6ftU_pSLxKzWkaLHPMEuyFgvxWDDTXebh_Z9ORio8qiMlXV7PlNAnOLFUU3XLp4XVK9qnhVlCyfw9XME1rXWXRk8LSA05mk3n_CvtBJD8deP2zdTMO0euMxFAm8T_tU3VFWy0nnRvmPYOfI5jsCNp_CTduFY8JnsfQesA8P9vQ98TnfC6MZjGI17GTNk5Cf53zsOw_PjjKaCtGSOzdmHCXm4vYy2fH4hwKKAzY8dIR0uMKGIeJITsX6PfxTMOLQ_UFbIMc9F2KXi9C9hwW7NVZWN2zWiLoPO7vWis4NakDMp2K12hqTLZLqy3Wbr_KjDvEFd7VeBzUJw_A2Wa_DMurg_CNOSfX5ON8IBddXfjF25rUu2mbzzC_eIRDhZSwjZe5wyLeDcrwVR0SUoShqEahn61fFd-oieRVWK_j5J61Wk261R3MxlsfiQPBOMm6OW8-sdSVOsCwFsa9sBHdhIaJLoCmEa6MleTVcW-jiZLEE3PYUsd4Uw_IZb_dRt4u2D_OXvITwNFrQhnwPGWb_0ss4eBQpf9MHE9IRNX7zILMnGmH2opE3TKGMy-tP2MW9qNeSfCOBtqMmco_7U50NACjg8_w1iC9haCXFrIiY0'
export const COMPANY_ID = 'fdb32fe8-fc8c-43bd-8616-23922361b5c8'
export const ROLE = 'user'
export const SENTRY_URL = REACT_APP_SENTRY
export const SENTRY_ENV = REACT_APP_SENTRY_ENV
export const CAPTCHA_SITE_KEY = REACT_APP_CAPTCHA_KEY
